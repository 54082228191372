<template>
  <v-row>
    <v-col cols="12" md="8">
      <TableDuplicateSets title="Duplicate sets" />
    </v-col>
    <v-col cols="12" md="4">
      <CompletedTasks />
    </v-col>
  </v-row>
</template>

<script>
import CompletedTasks from './components/CompletedTasks'
import TableDuplicateSets from '@/components/tables/TableDuplicateSets.vue'

export default {
  components: {
    CompletedTasks,
    TableDuplicateSets,
  },
}
</script>
